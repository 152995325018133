import React, { useEffect, useLayoutEffect } from "react";
import { NextSeo } from "next-seo";
import LoginForm from "domains/auth/components/LoginForm";
import usePersistedState, {
  PersistedStateKey,
} from "domains/commons/hooks/usePersistedState";
import useRouter from "domains/navigation/hooks/useRouter";
import Footer, { FOOTER_HEIGHT } from "domains/ui/components/Footer";
import Loading from "domains/ui/components/Loading";
import { useWindowSize } from "domains/ui/hooks/useWindowSize";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import {
  Box,
  Center,
  Flex,
  HStack,
  Image,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";

export default function LoginPage() {
  const router = useRouter();
  const { width: windowWidth } = useWindowSize();
  const isLargeScreen = (windowWidth ?? 0) > 1_000;
  const isMobile = (windowWidth ?? 0) <= 750;
  const [loginPageIdentifier] = usePersistedState(
    PersistedStateKey.LOGIN_PAGE_IDENTIFIER,
    {
      defaultValue: parseInt(Math.random().toString().slice(2)),
    }
  );
  const imageSrc = `/login/${(loginPageIdentifier % 3) + 1}.png`;

  useEffect(() => {
    const { referral } = router.query;

    if (referral && typeof referral === "string") {
      Track(AnalyticsEvents.Signup.ReferralCaptured, {
        referral,
      });
    }
  }, [router]);

  useLayoutEffect(() => {
    Track(AnalyticsEvents.Login.RenderedLoginPage, {
      image: imageSrc,
    });
  }, [imageSrc]);

  if (windowWidth === undefined) {
    return (
      <Center h="100vh">
        <Loading />
      </Center>
    );
  }

  return (
    <>
      <NextSeo title="Login" />

      <Box
        overflow="hidden"
        w="100vw"
        h={`calc(100vh - ${FOOTER_HEIGHT}px)`}
        bgColor="black"
      >
        <HStack pos="absolute" top={8} left={8} align="center" spacing={2}>
          <Image h={"36px"} alt={"logo"} src={"logos/scenario.svg"} />
          <Text userSelect="none" size="title.lg">
            Scenario
          </Text>
        </HStack>

        <Box
          pos="absolute"
          zIndex={0}
          top="calc(50vh - min(25vh, 25vw))"
          left={
            isMobile
              ? "calc(50vw - min(25vh, 25vw))"
              : "calc(60vw + min(25vh, 25vw) * -1)"
          }
          w="min(50vh, 50vw)"
          h="min(50vh, 50vw)"
          opacity={0.3}
          borderRadius="full"
          userSelect="none"
          pointerEvents="none"
          bgColor="primary.500"
          filter="blur(175px)"
        />

        <HStack
          zIndex={1}
          w="100vw"
          h={`calc(100vh - ${FOOTER_HEIGHT}px)`}
          spacing={0}
        >
          {!isMobile && (
            <VStack
              align="end"
              w="60%"
              h="full"
              pr={10}
              pl={isLargeScreen ? "10vw" : 10}
              borderColor="whiteAlpha.10"
              borderRightWidth={2}
            >
              <VStack
                align="start"
                justify="end"
                w="full"
                h="100%"
                pt="100px"
                pb={10}
                spacing={6}
              >
                <Text align="start" size="title.2xl">
                  Level Up Your Game.
                </Text>
                <Text align="start" color="textSecondary" size="body.2xl">
                  Generate content with unparalleled creative control and
                  consistency, powered by the most extensive Al toolkit.
                </Text>
              </VStack>
              <Flex align="end" justify="end" w="full" h="100%" pt={10}>
                <Box
                  overflow="hidden"
                  maxH="100%"
                  p={4}
                  pb={0}
                  borderTopRadius="2xl"
                  bgColor="whiteAlpha.50"
                >
                  <Image
                    w="100%"
                    borderTopRadius="lg"
                    alt="login image"
                    fallback={
                      <Skeleton
                        w="100vw"
                        maxW="100%"
                        borderTopRadius="lg"
                        aspectRatio="16 / 10"
                      />
                    }
                    src={imageSrc}
                  />
                </Box>
              </Flex>
            </VStack>
          )}
          <VStack
            align="center"
            justify="center"
            w={isMobile ? "100%" : "40%"}
            h="100%"
            pr={isLargeScreen ? "10vw" : 10}
            pl={10}
            bgColor="whiteAlpha.50"
          >
            <LoginForm />
          </VStack>
        </HStack>
      </Box>

      <Footer />
    </>
  );
}
