import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getIsEmailValid } from "domains/forms/getIsEmailValid";
import useRouter from "domains/navigation/hooks/useRouter";
import Button from "domains/ui/components/Button";
import Divider from "domains/ui/components/Divider";
import Icon, { IconProps } from "domains/ui/components/Icon";
import ScenarioInput from "domains/ui/components/ScenarioInput";

import {
  HStack,
  Link,
  PinInput,
  PinInputField,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useClerk, useSignIn, useSignUp } from "@clerk/nextjs";
import { OAuthStrategy } from "@clerk/types";

enum Step {
  EmailInput,
  CodeInputSignIn,
  CodeInputSignUp,
  SpecialPasswordInputE2E,
}

export default function LoginForm() {
  const router = useRouter();
  const { signIn } = useSignIn();
  const { signUp } = useSignUp();
  const { setActive } = useClerk();
  const [email, setEmail] = useState("");
  const [isProcessingEmail, setIsProcessingEmail] = useState(false);
  const [step, setStep] = useState<Step>(Step.EmailInput);
  const [isCodeInvalid, setIsCodeInvalid] = useState(false);
  const [isProcessingCode, setIsProcessingCode] = useState(false);
  const [password, setPassword] = useState("");
  const [isProcessingPassword, setIsProcessingPassword] = useState(false);

  useEffect(() => {
    if (router.query.email && router.isReady) {
      setEmail(router.query.email as string);
    }
  }, [router.isReady, router.query.email]);

  const isEmailInvalid = useMemo(() => !getIsEmailValid(email), [email]);

  const [wasEmailBlurred, setWasEmailBlurred] = useState(false);

  const showEmailInvalid = useMemo(
    () => wasEmailBlurred && isEmailInvalid && email,
    [wasEmailBlurred, isEmailInvalid, email]
  );

  const handleSetEmail = useCallback(
    (newEmail: string) => {
      setEmail(newEmail);
      setWasEmailBlurred(false);
    },
    [setEmail]
  );

  const signInOAuth = useCallback(
    async (strategy: OAuthStrategy) => {
      await signIn?.authenticateWithRedirect({
        strategy,
        redirectUrl: "/login?handleLoginRedirect=true",
        redirectUrlComplete: "/",
      });
    },
    [signIn]
  );

  const signInWithEmail = useCallback(async () => {
    if (email === "cypress@scenario.gg") {
      setStep(Step.SpecialPasswordInputE2E);
      return;
    }

    setIsProcessingEmail(true);
    try {
      await signIn?.create({
        strategy: "saml",
        redirectUrl: "/login?handleLoginRedirect=true",
        identifier: email,
      });
      // if saml isn't configured for this email it's going to throw an error, otherwise we log in him with saml
      await signIn?.authenticateWithRedirect({
        identifier: email,
        strategy: "saml",
        redirectUrl: "/login?handleLoginRedirect=true",
        redirectUrlComplete: "/",
      });
    } catch (_) {
      try {
        await signIn?.create({
          strategy: "email_code",
          identifier: email,
        });
        setStep(Step.CodeInputSignIn);
      } catch (_) {
        try {
          await signUp?.create({
            emailAddress: email,
          });
          await signUp?.prepareEmailAddressVerification();
          setStep(Step.CodeInputSignUp);
        } catch (_) {}
      }
    } finally {
      setIsProcessingEmail(false);
    }
  }, [signIn, email, signUp]);

  const onSignInWithCode = useCallback(
    async (code: string) => {
      setIsProcessingCode(true);
      try {
        if (step === Step.CodeInputSignIn) {
          await signIn?.attemptFirstFactor({
            strategy: "email_code",
            code,
          });
          if (signIn?.status === "complete" && signIn?.createdSessionId) {
            await setActive({
              session: signIn?.createdSessionId,
            });
          }
        } else {
          await signUp?.attemptEmailAddressVerification({
            code,
          });
          if (signUp?.status === "complete" && signUp?.createdSessionId) {
            await setActive({
              session: signUp?.createdSessionId,
            });
          }
        }
      } catch (_) {
        setIsCodeInvalid(true);
      }
      setIsProcessingCode(false);
    },
    [step, signIn, setActive, signUp]
  );

  const onSignInWithPassword = useCallback(async () => {
    setIsProcessingPassword(true);
    await signIn?.create({
      strategy: "password",
      identifier: email,
      password,
    });
    if (signIn?.status === "complete" && signIn?.createdSessionId) {
      await setActive({
        session: signIn?.createdSessionId,
      });
    }
    setIsProcessingPassword(false);
  }, [email, password, signIn, setActive]);

  return (
    <VStack w="full" spacing={5}>
      {step === Step.EmailInput && (
        <>
          <Text mb={2} textAlign="center" size="title.lg">
            Welcome to Scenario
          </Text>
          <Text textColor="textSecondary" textAlign="center" size="body.md">
            Continue with
          </Text>
          <HStack w="100%" spacing={2}>
            <OAuthButton type={"oauth_google"} onClick={signInOAuth} />
            <OAuthButton type={"oauth_microsoft"} onClick={signInOAuth} />
            <OAuthButton type={"oauth_github"} onClick={signInOAuth} />
            <OAuthButton type={"oauth_huggingface"} onClick={signInOAuth} />
          </HStack>
          <Divider label="OR" w="100%" />
          <ScenarioInput
            autoFocus
            onBlur={() => setWasEmailBlurred(true)}
            isDisabled={isProcessingEmail}
            value={email}
            setValue={handleSetEmail}
            placeholder="Email"
            type="email"
            borderColor={showEmailInvalid ? "danger.500" : undefined}
            onEnter={signInWithEmail}
          />
          {showEmailInvalid && (
            <Text color="danger.500" size="body.md">
              Please enter a valid email address
            </Text>
          )}
          <Button
            onClick={signInWithEmail}
            w="100%"
            isDisabled={!email || isEmailInvalid}
            colorScheme="white"
            variant="primary"
          >
            Continue with email
          </Button>
        </>
      )}
      {(step === Step.CodeInputSignIn || step === Step.CodeInputSignUp) && (
        <>
          <Text textAlign={"center"} size="title.md">
            Enter your verification code
          </Text>
          <VStack spacing={1}>
            <Text color="textSecondary" textAlign={"center"} size="body.md">
              Code sent to <b>{email}</b>
            </Text>
            <Text color={"textSecondary"} textAlign={"center"} size="body.sm">
              If not received, please check your spam folder
            </Text>
          </VStack>
          <HStack>
            <PinInput
              autoFocus
              isDisabled={isProcessingCode}
              isInvalid={isCodeInvalid}
              onChange={() => setIsCodeInvalid(false)}
              onComplete={onSignInWithCode}
              otp
              placeholder=""
              size="lg"
            >
              {[...Array(6)].map((_, index) => (
                <PinInputField
                  key={`pin-input-${index}`}
                  autoComplete="off"
                  // disable dashlane
                  data-form-type="other"
                  // disable lastpass
                  data-lpignore="true"
                />
              ))}
            </PinInput>
          </HStack>
          {isCodeInvalid && (
            <Text color="danger.500" size="body.md">
              Please enter a valid code
            </Text>
          )}
        </>
      )}
      {
        // This is a special case for E2E tests
        // It uses password instead of code
        step === Step.SpecialPasswordInputE2E && (
          <>
            <ScenarioInput
              isDisabled={isProcessingPassword}
              autoFocus
              setValue={setPassword}
              onEnter={onSignInWithPassword}
              placeholder="Password"
              type="password"
              value={password}
            />
            <Button
              variant="primary"
              colorScheme="white"
              onClick={onSignInWithPassword}
              isDisabled={!password || isProcessingPassword}
              w="100%"
            >
              Continue
            </Button>
          </>
        )
      }
      <Text align="center" textColor="textSecondary" size="body.md">
        {"By using Scenario, you agree to our "}
        <Link
          color="textPrimary"
          href="https://www.scenario.com/terms-and-conditions"
          target="_blank"
        >
          Terms and Conditions
        </Link>
        {" and "}
        <Link
          color="textPrimary"
          href="https://www.scenario.com/privacy-policy"
          target="_blank"
        >
          Privacy Policy
        </Link>
      </Text>
    </VStack>
  );
}

function OAuthButton({
  type,
  onClick,
}: {
  type:
    | "oauth_google"
    | "oauth_microsoft"
    | "oauth_github"
    | "oauth_huggingface";
  onClick: (type: OAuthStrategy) => void;
}) {
  const icons: {
    [key in typeof type]: IconProps["id"];
  } = {
    oauth_google: "Google",
    oauth_microsoft: "Microsoft",
    oauth_github: "GitHub",
    oauth_huggingface: "HuggingFace",
  };
  return (
    <Button onClick={() => onClick(type)} variant="glass" flex={1} size="lg">
      <Icon id={icons[type]} />
    </Button>
  );
}
